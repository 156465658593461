import Vue from 'vue'
import App from './App.vue'
import Norway from './components/Norway.vue'
import Business from './components/Business.vue'
import Rectangle from './components/Rectangle.vue'
import Netherlands from './components/Netherlands.vue';
import England from './components/England.vue';
import Sweden from './components/Sweden.vue';
import Turkey from './components/Turkey.vue';


import '@/assets/autoptimize_02631c4193d1c9a60e10a1dd41d05a52.css';
import router from './router';

function getUclick() {
  var m = document.cookie.match(new RegExp('(?:^|; )' + 'uclick' + '=([^;]*)'));
  var u = m ? decodeURIComponent(m[1]) : undefined;
  return u;
}

const query = window.location.search.substring(1);
const queryParams = query.split('&').reduce((acc, curr) => {
  const [key, value] = curr.split('=')
  acc[key] = value
  return acc;
}, {});



window.addEventListener("load", function () {
  if (queryParams.key) {
    delete queryParams.key;
  }
  const uclick = getUclick();
  if (uclick) {
    queryParams.uclick = uclick;
  }
  queryParams.fbdomain = window.location.origin;
  const sanitized = Object.entries(queryParams).map(([a, b]) => `${a}=${b}`).join('&')

  Vue.mixin({
    data() {
      return {
        clickerUrl: `http://ee168f.binomlink.com/click.php?lp=1&${sanitized}`
      }
    }
  })


  Vue.config.productionTip = false

  Vue.component('norway', Norway);
  Vue.component('business', Business);
  Vue.component('rectangle', Rectangle);
  Vue.component('netherlands', Netherlands);
  Vue.component('england', England);
  Vue.component('sweden', Sweden);
  Vue.component('turkey', Turkey);

  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
  // here is the Vue code
});