<template>
  <header class="header" :class="{ 'hamburger-active': isShown }">
    <div class="header-container">
      <router-link to="/">
        <img src="/logo.svg" alt="logo" class="header_logo lazyloaded" />
      </router-link>
      <div class="header_nav">
        <div class="nav_item"><router-link to="/"> Blog </router-link></div>
        <div class="nav_item">
          <router-link to="/about"> About </router-link>
        </div>
      </div>
      <div
        id="hamburger"
        class="hamburger-icon-container"
        @click="isShown = !isShown"
      >
        <span class="hamburger-icon"> </span>
        <div class="mob_menu" :class="{ hide: !isShown }">
          <ul>
            <li><router-link to="/"> Blog </router-link></li>
            <li><router-link to="/about"> About </router-link></li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      isShown: false,
    };
  },
};
</script>