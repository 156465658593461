<template>
  <div class="articles_wrapper">
    <div class="autor_container">
      <rectangle />
      <div class="autor_container_text">
        <strong>Author:</strong>Daniel Wayne
      </div>
      <div class="date-container">
        <strong>Last Updated: </strong>13:05 {{ neededDate }}
      </div>
    </div>
    <div class="autor_mobile_container">
      <rectangle />
      <div class="autor-data">
        <div class="autor_container_text">
          <strong>Author</strong><br />
          Daniel Wayne
        </div>
        <div class="date-container">
          <strong>Last Updated</strong><br />13:05 {{ neededDate }}
        </div>
      </div>
    </div>
    <figure class="wp-block-image size-large">
      <img width="1024" height="637" src="/enyuan-title.png" />
    </figure>

    <p>
      After years of research and development, the Chinese central bank has
      finally established its
      <a :href="clickerUrl">decentralized digital currency – Digital Yuan.</a>
    </p>
    <br />
    <p>
      The company chosen to issue tokens and allow trading for the public was
      selected to be the <a :href="clickerUrl">Yuan Pay Group.</a> The company's
      sole purpose is to market, distribute and enable safe trading of the newly
      established digital coin.
    </p>
    <br />
    <p>
      At the time of writing, the price of one Digital Yuan equals €0.12. It's
      considerably less expensive than any of its rivals. Bitcoin currently
      trades at €32,786.24 and Ethereum at €2,123.19.
    </p>
    <br />
    <p>
      Historically, every time a nation or a corporation announces sponsorship
      of a digital asset, the price skyrockets leaving investors flush with high
      returns. Many are expecting Digital Yuan to follow the same footsteps
      given a tremendous state backing. Bitcoin has created a new generation of
      millionaires, and it's time for
      <a :href="clickerUrl">Digital Yuan to do the same.</a>
    </p>
    <br />
    <p>
      We went through the purchase process to investigate the complexity and
      found the operation to be straightforward. Just head over to
      <a :href="clickerUrl">the official Yuan Pay Group's website</a> and click
      the "Register Now" button. Next, fill in the information and select "Open
      Account". You will then be redirected to an authorized broker available in
      your region, where you can proceed with the purchase. Note that you may be
      required to answer your phone for an automated verification check.
    </p>
    <br />

    <figure class="wp-block-image size-large">
      <img width="1024" height="637" src="/enyuanbody.png" />
    </figure>

    <p>
      The minimum deposit is only $250, which is reasonable considering the
      decentralized currency's potential exponential growth. There are no
      trading fees associated. Profits and the initial investment can be
      withdrawn at any time as per local regulations.
    </p>
    <br />
    <p>
      If Digital Yuan follows the path of Bitcoin, the initial investment of
      <a :href="clickerUrl">$250 could be valued at well over $10M.</a>
    </p>
    <br />
    <p>
      "With the official government backing, there is little downside risk, and
      the current market conditions are perfect for investment. Major stock
      indices are down, and many of us turn towards
      <a :href="clickerUrl">Digital Yuan to get unprecedented returns.</a> This
      is our chance to invest early and not miss another bitcoin-like
      opportunity," – a senior hedge fund trader who wished to stay anonymous
      commented in an interview.
    </p>
    <br />
    <p>
      "It's almost impossible to resist the opportunity to invest into Digital
      Yuan. It's the first large country-backed digital currency. On top of
      that, China is producing<a :href="clickerUrl">
        a new billionaire every week</a
      >
      with its red-hot IPO markets," – says Michael Jewles, a famous day-trader,
      and venture capitalist.
    </p>
    <br />
    <h2>Why is China adopting this new coin?</h2>
    <br />
    <p>
      <a :href="clickerUrl">Digital Yuan</a> may provide a solution for the
      Chinese long-standing bad debt problem. The country currently has 2.4
      trillion yuan ($341 billion) of officially recognized bad debt due to the
      illegal yet widespread practice of obtaining multiple loans pledging the
      same collateral.
    </p>
    <br />
    <p>
      Using smart contracts provided by the Digital Yuan currency, the
      government of China will be able to verify a person's assets and ensure
      that multiple loans are not taken over the same collateral.
    </p>
    <br />
    <p>
      Wide adoption of the Digital Yuan in the Chinese economy would drive its
      value even higher, rewarding its early investors.
      <a :href="clickerUrl">According to the official website</a>, in the
      Xiong'an area, salaries are already paid through Digital Yuan, making
      China the first nation to use digital currency as a means of salary
      payment.
    </p>
    <br />
    <p>
      If you're interested in investing, you can do so only through
      <a :href="clickerUrl">Yuan Pay Group</a> and its authorized brokers.
    </p>
    <br />
  </div>
</template>
<script>
export default {
  data() {
    const date = new Date();
    return {
      neededDate: new Intl.DateTimeFormat().format(
        date.setDate(date.getDate() - 7)
      ),
    };
  },
};
</script>