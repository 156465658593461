import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Business from '../views/Business.vue';
import About from '../views/About.vue';
import ArticleOne from '../views/ArticleOne.vue';
import PageTwo from '../views/PageTwo.vue';
import ArticleTwo from '../views/ArticleTwo';
import ArticleFour from '../views/ArticleFour.vue'
import ArticleFive from '../views/ArticleFive.vue'
import ArticleSix from '../views/ArticleSix.vue'
import ArticleSeven from '../views/ArticleSeven.vue';
import ArticleEight from '../views/ArticleEight.vue';
import ArticleNine from '../views/ArticleNine.vue';
import YanNl from '../views/YanNl.vue';
import YanEs from '../views/YanEs.vue';
import YanIe from '../views/YanIe.vue';
import YanAu from '../views/YanAu.vue';
import YanDe from '../views/YanDe.vue';
import YanNo from '../views/YanNo.vue';
import YanIt from '../views/YanIt.vue';
import Terms from '../views/Terms.vue'
import ArticleTen from '../views/ArticleTen.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import CookiePolicy from '../views/CookiePolicy.vue';
import EnYuan from '../views/EnYuan.vue';




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'YUAN PAY GROUP BLOG',
      subtitle: 'YUAN PAY GROUP OFFICIAL BLOG'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { title: "About Us" },
  },
  {
    path: '/category/business',
    component: Business,
    meta: {
      title: "YUAN PAY GROUP BLOG",
      subtitle: "YUAN PAY GROUP OFFICIAL BLOG",
    }
  },
  {
    component: ArticleOne,
    path: '/digital-yuan-creeert-een-nieuwe-generatie-miljonairs-onder-traders',
    meta: {
      title: 'Digital Yuan creëert een Nieuwe Generatie Miljonairs onder Traders'
    }
  },
  {
    component: PageTwo,
    path: '/page/2/',
    meta: {
      title: "YUAN PAY GROUP BLOG",
      subtitle: "YUAN PAY GROUP OFFICIAL BLOG",
    }
  },
  {
    component: ArticleTwo,
    path: '/digital-yuan-skaper-en-ny-generasjon-av-millionaer-tradere',
    meta: {
      title: "Digital Yuan creëert een Nieuwe Generatie Miljonairs onder Traders",
    }
  },
  {
    component: ArticleFour,
    path: '/digital-yuan-skapar-en-ny-generation-av-miljonarhandlare',
    meta: {
      title: 'Digital Yuan skapar en ny generation av miljonärhandlare'
    }
  },
  {
    component: ArticleFive,
    path: '/china-digital-cryptocurrency-payments-minus-the-crypto-2',
    meta: {
      title: 'China: Digital cryptocurrency payments, minus the crypto.'
    }
  },
  {
    path: '/category/business/page/2',
    component: ArticleSix,
    meta: {
      title: 'YUAN PAY GROUP BLOG',
      subtitle: 'YUAN PAY GROUP OFFICIAL BLOG',
    }
  },
  {
    path: '/duis-aute-irure-dolor-in-reprehenderit-in-voluptate-velit-esse-cillum-10',
    component: ArticleSeven,
    meta: {
      title: 'Opin-yuan Piece: The digital Yuan can end poverty in China'
    }
  },
  {
    path: '/duis-aute-irure-dolor-in-reprehenderit-in-voluptate-velit-esse-cillum-11',
    component: ArticleEight,
    meta: {
      title: 'Bitcoin vs. Digital Yuan: Which would you choose to use?'
    }
  },
  {
    path: '/duis-aute-irure-dolor-in-reprehenderit-in-voluptate-velit-esse-cillum-9',
    component: ArticleNine,
    meta: {
      title: 'Same, same, but different'
    }
  },
  {
    path: '/yan-nl',
    component: YanNl,
    meta: {
      title: 'Digital Yuan wordt beschouwd als “Hoogste Potentiële Opbrengst” Investering door Onafhankelijke Onderzoekers'
    }
  },
  {
    path: '/yan-es',
    component: YanEs,
    meta: {
      title: 'Digital Yuan Es Considerado Como Una Inversión De “Mayor Potencial De Retorno” Por Los Investigadores Independientes'
    }
  },
  {
    path: '/yan-ie',
    component: YanIe,
    meta: {
      title: 'Digital Yuan Deemed a ‘Highest Potential Return’ Investment by Independent Researchers'
    }
  },
  {
    path: '/yan-au',
    component: YanAu,
    meta: {
      title: 'Digital Yuan Deemed a ‘Highest Potential Return’ Investment by Independent Researchers'
    }
  },
  {
    path: '/yan-de',
    component: YanDe,
    meta: {
      title: 'Digital Yuan wurde von unabhängigen Marktforschern als ‘Investition mit dem höchsten Renditepotential’ eingestuft'
    }
  },
  {
    path: '/yan-no',
    component: YanNo,
    meta: {
      title: 'Digital Yuan Ansett som en “Høyeste Potensielle Avkastning” Investering av Uavhengige Forskere'
    }
  },
  {
    path: '/duis-aute-irure-dolor-in-reprehenderit-in-voluptate-velit-esse-cillum-12',
    component: ArticleTen,
    meta: {
      title: 'China: A story of currency.'
    }
  },
  {
    path: '/yan-it',
    component: YanIt,
    meta: {
      title: 'Digital Yuan Offre Guadagni Stellari mentre Sempre più Investitori Stanno Andando All-In'
    }
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    meta: {
      title: 'Privacy Policy'
    }
  },
  {
    path: '/cookie-policy',
    component: CookiePolicy,
    meta: {
      title: 'Cookie Policy'
    }
  },
  {
    path: '/terms-conditions',
    component: Terms,
    meta: {
      title: 'Terms & Conditions'
    }
  },
  {
    path: '/en-yuan',
    component: EnYuan,
    meta: {
      title: 'China Establishes Digital Yuan as Their Official Coin and Digital Currency'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth', }
  }
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
   if(!hasQueryParams(to) && hasQueryParams(from)){
    next({ ...to, query: from.query });
  } else {
    next()
  }
})


export default router


