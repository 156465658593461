<template>
  <div class="articles_wrapper cookies">
    <p>
      <strong>Cookie Policy</strong><br />
      February 01, 2020<br />
      {{ url }} is committed to protecting your privacy online.
    </p>
    <p><strong>What information do we collect?</strong></p>
    <p>
      We collect information from you when you visit our site. When submitting
      or registering on our site, you may be asked to enter your: name or e-mail
      address. You may, however, visit our site anonymously.
    </p>
    <p><strong>What information do we collect?</strong></p>
    <p>
      We collect information from you when you visit our site. When submitting
      or registering on our site, you may be asked to enter your: name or e-mail
      address. You may, however, visit our site anonymously.
    </p>
    <p><strong>What do we use your information for?</strong></p>
    <p>
      Any of the information we collect from you may be used in one of the
      following ways:
    </p>
    <address>-To improve our website</address>
    <address>-To improve customer service</address>
    <p><strong>Cookies</strong></p>
    <p>
      Cookies are small files that a site or its service provider transfers to
      your computers hard drive through your Web browser (if you allow) that
      enables the sites or service providers systems to recognize your browser
      and capture and remember certain information We use cookies to understand
      and save your preferences for future visits.
    </p>
    <p><strong>Online Privacy Policy Only</strong></p>
    <p>
      This online privacy policy applies only to information collected through
      our website and not to information collected offline.
    </p>
    <p><strong>Changes to our Privacy Policy</strong></p>
    <p>
      If we decide to change our privacy policy, we will post those changes on
      this page. If you have any questions and suggestions regarding our Privacy
      Policy Statement, please contact us and we will get back to you very soon.
    </p>
  </div>
</template>
<script>
export default {
  data() {
    const url = window.location.origin.replace('https://', '');
    return { url };
  },
};
</script>
<style scoped>
p {
  margin: 0.66666667em 0 1em;
}
.cookies {
  color: #666;
  font-size: 15px;
}
strong,
b {
  font-weight: 700;
}
</style>
