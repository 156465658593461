<template>
  <div id="home blog">
    <HeaderView />
    <main class="page>">
      <section class="jumbo">
        <img
          class="lazyload header-background-mobile"
          src="/mobile-header.jpeg"
        />
        <div class="jubmo_text">
          <h1>
            {{ $route.meta.title }}
          </h1>
          <h3>
            {{ $route.meta.subtitle }}
          </h3>
        </div>
      </section>
      <router-view />
      <FooterView />
    </main>
  </div>
</template>
<script>
import FooterView from "@/components/Footer";
import HeaderView from "@/components/HeaderView";
export default {
  components: {
    FooterView,
    HeaderView,
  },
  props: {
    headerTitle: String
  }
};
</script>
